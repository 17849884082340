import { useState, useEffect, useRef } from 'react';
import Script from 'next/script';
import {
  useCustomerAuth,
} from '@backpackjs/storefront';
import Link from 'next/link';

export function AccountNav() {
  const { customerInit, customer } = useCustomerAuth();
  const rewardsPopupBtn = useRef(null);

  const getPage = () => {
    if (
      window.location.pathname === '/account' ||
      window.location.pathname === '/account/orders'
    ) {
      return 'orders';
    }
    if (window.location.pathname === '/account/address-book') {
      return 'addresses';
    }
    if (window.location.pathname === '/account/referral') {
      return 'referral';
    }
  };

  useEffect(() => {
    if (customerInit) {
      if (
        typeof yotpoWidgetsContainer !== 'undefined' &&
        typeof yotpoWidgetsContainer?.initWidgets !== 'undefined'
      ) {
        window.yotpoWidgetsContainer.initWidgets();
      }
    }
  }, []);

  return (
    <div className="account-nav">
      <ul>
        <li>
          <Link href={`${getPage() === 'orders' ? '#' : '/account/orders'}`}>
            <a className={`${getPage() === 'orders' ? 'active' : ''}`}>
              Order History
            </a>
          </Link>
        </li>
        <li>
          <Link
            href={`${
              getPage() === 'addresses' ? '#' : '/account/address-book'
            }`}
          >
            <a className={`${getPage() === 'addresses' ? 'active' : ''}`}>
              Addresses
            </a>
          </Link>
        </li>
        <li>
          <Link
            href={`${getPage() === 'referral' ? '#' : '/account/referral'}`}
          >
            <a className={`${getPage() === 'referral' ? 'active' : ''}`}>
              Refer A Friend
            </a>
          </Link>
          <span className="text-xsmall ml-3 align-middle font-gothamBold text-primary-2">
            Earn 500 pts!
          </span>
        </li>
      </ul>
    </div>
  );
}
