import { useMemo } from 'react';
import { useCustomerCreate } from '@backpackjs/storefront';
import { useDataLayerActions } from '@hooks';

export function RegisterForm() {
  const {
    customerCreate,
    started,
    finished,
    errors,
    setErrors,
    success,
    reset,
    resetTimer,
  } = useCustomerCreate();
  const { sendRegisterEvent } = useDataLayerActions();

  const buttonText = useMemo(() => {
    let newSuccess = success;
    const succeededWithError = errors?.some((e) => e.indexOf('verify') > -1);

    if (succeededWithError) {
      newSuccess = true;
    };

    if (finished && !succeededWithError) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 5000);
    }

    return finished
      ? newSuccess
        ? 'Registered'
        : 'Failed'
      : // not finished yet
      started
      ? 'Registering...'
      : 'Sign Up';
  }, [started, success, finished]);

  const customerCreateHandler = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, password, passwordConfirm } =
      event.target;

    if (password.value !== passwordConfirm.value) {
      setErrors(['Passwords do not match']);

      if (finished) {
        resetTimer.current = setTimeout(() => {
          reset && reset();
          clearTimeout(resetTimer.current);
        }, 5000);
      }
      return;
    }

    await customerCreate({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      acceptsMarketing: false,
    });

    sendRegisterEvent();
  };

  return (
    <div className="px-4 py-[50px] lg:py-20">
      <form
        className="mx-auto w-full max-w-[432px] text-center"
        onSubmit={customerCreateHandler}
      >
        <h3 className="mb-3 lg:mb-4">Sign Up</h3>
        <p className="text-small mb-7 font-light lg:text-base">
          Already have an account? <a href="/account/login">Sign In</a>
        </p>

        {errors.length > 0 && (
          <div className="relative mb-[22px] w-full px-7 py-6">
            <span className="absolute inset-0 h-full w-full bg-error opacity-10" />
            {errors?.indexOf('Email has already been taken') > -1 ? (
              <p className="mb-0 text-[14px] leading-5 text-error">
                <>This email address is already associated with an account. If this
                account is yours, please try <b>resetting your password</b></>
              </p>
            ) : (
              errors.map((err, index) => {
                return <p key={`error-${index}`} className={`text-[14px] leading-5 text-error ${index == errors.length - 1 && 'mb-0'}`}>{err}</p>
              })
            )}
          </div>
        )}

        <div className="form-field mb-[22px]">
          <input
            id="registerFirstName"
            placeholder=" "
            name="firstName"
            type="text"
            required
          />
          <label htmlFor="registerFirstName"> First Name </label>
        </div>

        <div className="form-field mb-[22px]">
          <input
            id="registerLastName"
            placeholder=" "
            name="lastName"
            type="text"
            required
          />
          <label htmlFor="registerLastName"> Last Name </label>
        </div>

        <div className="form-field mb-[22px]">
          <input
            className={`${
              errors?.find((e) => e.indexOf('Email') > -1) ? 'has-error' : ''
            }`}
            id="registerEmail"
            placeholder=" "
            name="email"
            type="email"
            required
          />
          <label htmlFor="registerEmail"> Email Address </label>
          {errors?.find((e) => e.indexOf('Email') > -1)?.length
            ? errors.map((err) => (
                <p className="error">
                  {err === 'Email has already been taken'
                    ? 'Email is already being used'
                    : err}
                </p>
              ))
            : null}
        </div>

        <div className="form-field mb-[22px]">
          <input
            id="registerPassword"
            placeholder=" "
            name="password"
            type="password"
            required
          />
          <label htmlFor="registePassword"> Password </label>
        </div>

        <div className="form-field mb-[22px]">
          <input
            className={`${
              errors?.indexOf('Passwords do not match') > -1 ? 'has-error' : ''
            }`}
            id="registerPasswordConfirm"
            placeholder=" "
            name="passwordConfirm"
            type="password"
            required
          />
          <label htmlFor="registePasswordConfirm"> Confirm Password </label>
          {errors?.indexOf('Passwords do not match') > -1 && (
            <p className="error">Passwords do not match</p>
          )}
        </div>

        <button
          type="submit"
          className="button button--primary mb-[22px] w-full"
          disabled={started || (finished && success)}
        >
          <span className="text-base">{buttonText}</span>
        </button>
      </form>
    </div>
  );
}
