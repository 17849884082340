import { useState } from 'react';
import {
  useCustomerAddresses,
  useCustomerDefaultAddress,
  useCustomerDeleteAddress,
} from '@backpackjs/storefront';

import Link from 'next/link';
import AccountLayout from '../../layouts/AccountLayout';
import { Close } from '@/snippets/icons/Close';
import { AddressForm } from '@/snippets/account/AddressForm';

export function AddressBook() {
  const defaultAddress = useCustomerDefaultAddress();
  const { addresses, ...status } = useCustomerAddresses();
  const { deleteAddress, ...deleteStatus } = useCustomerDeleteAddress();

  const removeAddress = (addr) => {
    deleteAddress({ id: addr.id }, () => {
      console.log('Address removed!');
    });
  };

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [createAddress, setCreateAddress] = useState(false);

  if (status.started) {
    return (
      <AccountLayout>
        <p> Loading Addresses ... </p>
      </AccountLayout>
    );
  }

  if (createAddress) {
    return (
      <AccountLayout>
        <div className="address-book px-4 pt-3 pb-10 lg:px-0 lg:py-20">
          <Link href="/account/orders">
            <a className="focus-link text-small mb-3 lg:hidden">
              Back to Order History
            </a>
          </Link>
          <h3 className="mb-6 md-:mb-9">Add Address</h3>
          <AddressForm
            closeForm={() => {
              setCreateAddress(false);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </div>
      </AccountLayout>
    );
  }

  if (!defaultAddress || !addresses?.length) {
    return (
      <AccountLayout>
        <div className="address-book px-4 pt-3 pb-10">
          <Link href="/account/orders">
            <a className="focus-link text-small mb-3 lg:hidden">
              Back to Order History
            </a>
          </Link>
          <h3 className="mb-4">Addresses</h3>
          <ul className="-mx-3 my-0">
            <li className="w-full px-3 md-:w-1/2 lg:max-w-[408px]">
              <div className="address-item">
                <button
                  onClick={() => setCreateAddress(true)}
                  className="focus-link m-auto flex flex-col items-center justify-center"
                >
                  <span className="mb-2.5 rotate-45 transform md-:mb-3">
                    <Close />
                  </span>
                  <span className="text-small">add new address</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </AccountLayout>
    );
  }

  if (selectedAddress) {
    return (
      <AccountLayout>
        <div className="address-book px-4 pt-3 pb-10">
          <Link href="/account/orders">
            <a className="focus-link text-small mb-3 lg:hidden">
              Back to Order History
            </a>
          </Link>
          <h3 className="mb-4">Addresses</h3>
          <AddressForm
            address={selectedAddress}
            closeForm={() => {
              setSelectedAddress(null);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            addressIsDefault={
              defaultAddress.id.split('?')[0] ===
              selectedAddress.id.split('?')[0]
            }
          />
        </div>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout>
      <div className="address-book px-4 pt-3 pb-10">
        <Link href="/account/orders">
          <a className="focus-link text-small mb-3 lg:hidden">
            Back to Order History
          </a>
        </Link>
        <h3 className="mb-4">Addresses</h3>
        <ul className="-mx-3 my-0 flex flex-wrap">
          <li
            className="w-full px-3 md-:w-1/2 lg:max-w-[408px]"
            key={defaultAddress.id}
          >
            <div className="address-item">
              <div>
                <p className="mb-2">
                  {defaultAddress.firstName} {defaultAddress.lastName}
                </p>
                <p className="mb-1">
                  {defaultAddress.address1}, {defaultAddress.address2}
                </p>
                <p className="mb-1">
                  {defaultAddress.city},{' '}
                  {defaultAddress.provinceCode || defaultAddress.province}{' '}
                  {defaultAddress.zip}
                </p>
                <p className="mb-1">{defaultAddress.country}</p>
                {/* <p className="mb-1">{defaultAddress.phone}</p> */}
              </div>
              <p className="mb-0 font-gothamBold text-[14px] leading-5 text-primary-2">
                Default Address
              </p>
              <div>
                <button
                  className="button button--secondary mr-8 min-w-[135px]"
                  onClick={() => setSelectedAddress(defaultAddress)}
                >
                  <span className="text-base">edit</span>
                </button>
                <button
                  className="focus-link"
                  onClick={() => removeAddress(defaultAddress)}
                >
                  delete
                </button>
              </div>
            </div>
          </li>
          {addresses?.map((address) => {
            const {
              address1,
              address2,
              city,
              company,
              country,
              firstName,
              id,
              lastName,
              phone,
              province,
              provinceCode,
              zip,
            } = address;
            const isDefaultAddress =
              defaultAddress.id.split('?')[0] === id.split('?')[0];

            return (
              !isDefaultAddress && (
                <li className="w-full px-3 md-:w-1/2 lg:max-w-[408px]" key={id}>
                  <div className="address-item">
                    <div>
                      <p className="mb-2">
                        {firstName} {lastName}
                      </p>
                      <p className="mb-1">
                        {address1}, {address2}
                      </p>
                      <p className="mb-1">
                        {city}, {provinceCode || province} {zip}
                      </p>
                      <p className="mb-1">{country}</p>
                      {/* <p className="mb-1">{phone}</p> */}
                    </div>
                    <div>
                      <button
                        className="button button--secondary mr-8 min-w-[135px]"
                        onClick={() => {
                          setSelectedAddress(address);
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                      >
                        <span className="text-base">edit</span>
                      </button>
                      <button
                        className="focus-link"
                        onClick={() => removeAddress(address)}
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </li>
              )
            );
          })}
          <li className="w-full px-3 md-:w-1/2 lg:max-w-[408px]">
            <div className="address-item">
              <button
                onClick={() => {
                  setCreateAddress(true);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="focus-link m-auto flex flex-col items-center justify-center"
              >
                <span className="mb-2.5 rotate-45 transform md-:mb-3">
                  <Close />
                </span>
                <span className="text-small">add new address</span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </AccountLayout>
  );
}
