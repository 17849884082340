import Link from 'next/link';
import AccountLayout from '../../layouts/AccountLayout';
import SwellPointBalance from '@/snippets/account/AccountNav/SwellPointBalance';

export function Rewards({ params }) {
  return (
    <AccountLayout>
      <div className="account-rewards px-4 pt-3 pb-10 lg:max-w-[790px] lg:p-0">
        <Link href="/account/orders">
          <a className="focus-link text-small mb-3 lg:hidden">
            Back to Order History
          </a>
        </Link>
        <h3 className="mb-4 lg:mb-14">Rewards</h3>
        <div className="mb-9 flex w-full flex-col items-center bg-[#EDF0F5] py-6 px-16 lg:py-10">
          <h6 className="mb-4 lg:mb-5">My Rewards</h6>
          <span className="mb-4 inline-flex flex-col items-center border border-quinary-2 lg:mb-7">
            <span className="bg-quinary-2 px-8 py-1.5 text-base">
              Points Balance
            </span>
            <span className="pt-1 pb-2 font-gothamBold text-[24px] leading-10">
              <SwellPointBalance />
            </span>
          </span>
          <p className="text-center font-light">
            Earn more rewards with every purchase!
          </p>
        </div>
        <Link href="/pages/rewards">
          <a className="button button--secondary mx-auto block w-full max-w-[270px] text-center text-[16px] leading-[26px] no-underline">
            how to earn
          </a>
        </Link>
      </div>
    </AccountLayout>
  );
}
