import { useEffect, useRef, useState } from "react";
import { useCustomerOrder } from "@backpackjs/storefront";
import { formatMoney } from "@/utils/Helper";
import { Image } from "@/snippets/components/Image";
import Link from "next/link";

export default function OrderItem({orderData}) {
  const orderItemDetail = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { order, ...status } = useCustomerOrder({ id: orderData?.id });

  const toggleOrderDetail = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="order-item flex-col">
      <div className="order-item__header w-full md-:pl-7 md-:pr-5">
        <div className="md-:w-[18%]">
          <span className="font-gothamBold md-:hidden">Date</span>
          <span className="font-light">
            {new Date(orderData.processedAt).toLocaleDateString()}
          </span>
        </div>
        <div className="md-:w-1/5">
          <span className="font-gothamBold md-:hidden">Order</span>
          <span className="font-gothamBold">{orderData.name.replace('#', '')}</span>
        </div>
        <div className="md-:w-1/5">
          <span className="font-gothamBold md-:hidden">Status</span>
          <span className="font-light">
            {orderData.fulfillmentStatus === 'FULFILLED'
              ? 'Shipped'
              : 'Not shipped'}
          </span>
        </div>
        <div className="md-:w-1/5">
          <span className="font-gothamBold md-:hidden">Total</span>
          <span className="font-light">
            ${Number.parseFloat(order?.totalPriceV2?.amount || 0, 2)}
          </span>
        </div>
        <div className="md-:w-[22%] md-:!justify-end">
          <button type="button" onClick={toggleOrderDetail} className="button button--outline !w-full">
            {isOpen ? 'Close' : 'View Order'}
          </button>
        </div>
      </div>
      {(isOpen && status.finished) &&
        <div
          className="order-item__details w-full !block"
          ref={orderItemDetail}
        >
          <div className="order-details__status relative flex flex-col md-:flex-row md-:pl-7 md-:pr-5 gap-5 md-:gap-0 py-5 mt-5 border-t border-grey-7">
            <div className="order-details__block md-:w-[18%] pr-2 flex justify-between md-:block">
              <b className="md-:mb-5 block">Payment Status</b>
              <span className="block">{order.financialStatus}</span>
            </div>
            <div className="order-details__block md-:w-1/5 flex justify-between md-:block">
              <b className="md-:mb-5 block">Shipping Address</b>
              <address className="text-right md-:text-left">
                {order.shippingAddress?.address1}
                <br />
                {order.shippingAddress?.city}, {order.shippingAddress?.province}
                <br />
                {order.shippingAddress?.zip}, {order.shippingAddress?.country}
              </address>
            </div>
          </div>
          <div className="order-item__products-detail relative md-:pl-7 md-:pr-5 pt-5 border-t border-grey-7">
            <div className="md-:flex mb-6 md-:mb-9 gap-5">
              <b className="md-:w-7/12">
                Product
              </b>
              <b className="hidden md-:block md-:w-5/12">
                Price
              </b>
            </div>
            {order.lineItems.map((item, index) => {
              return (
                <div
                  key={`item-${item.id}`}
                  className={`order__line-item mb-6 md-:mb-9 flex gap-5`}
                >
                  <div className="product-container md-:w-7/12">
                    <div className="flex gap-5">
                      {item?.variant?.image ? (
                        <Image
                          src={item.variant?.image.src}
                          alt={item.variant?.image?.altText}
                          height="96"
                          width="96"
                        />
                      ) : (
                        <div className="bg-grey-7 flex h-24 w-24 shrink-0 items-center justify-center">
                          <span>No Image</span>
                        </div>
                      )}
                      {item?.variant?.product ? (
                        <Link href={`/products/${item.variant.product.handle}`}>
                          <a className="no-underline">
                            <b className="text-xs">{item?.title}</b>
                            <div className="md-:hidden">
                              <span className="block text-sm">
                                {item?.discountedTotalPrice?.amount <
                                  item?.originalTotalPrice?.amount && (
                                  <span className="line-through mr-1 text-grey-3">
                                    {formatMoney(item?.originalTotalPrice.amount)}
                                  </span>
                                )}

                                <span>
                                  {formatMoney(item?.discountedTotalPrice.amount)}
                                </span>
                              </span>
                            </div>
                          </a>
                        </Link>
                      ) : (
                        <p>
                          <b className="text-xs">{item?.title}</b>
                          <div className="md-:hidden">
                            <span className="block text-sm">
                              {item?.discountedTotalPrice?.amount <
                                item?.originalTotalPrice?.amount && (
                                <span className="line-through mr-1 text-grey-3">
                                  {formatMoney(item?.originalTotalPrice.amount)}
                                </span>
                              )}

                              <span>
                                {formatMoney(item?.discountedTotalPrice.amount)}
                              </span>
                            </span>
                          </div>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="price-container hidden md-:block md-:w-5/12 text-sm">
                    {item?.discountedTotalPrice?.amount <
                      item?.originalTotalPrice?.amount && (
                      <span className="line-through mr-1 text-grey-3">
                        {formatMoney(item?.originalTotalPrice.amount)}
                      </span>
                    )}

                    <span>{formatMoney(item?.discountedTotalPrice.amount)}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="order-item__finnancial-details relative md-:pl-7 md-:pr-5 pt-5 border-t border-grey-7">
            <div class="w-full max-w-sm flex justify-between mb-3">
              <span>
                Subtotal
              </span>
              <span>
                {formatMoney(order.subtotalPriceV2.amount)}
              </span>
            </div>
            <div class="w-full max-w-sm flex justify-between mb-3">
              <span>
                Shipping *
              </span>
              <span>
                {formatMoney(order.totalShippingPriceV2.amount)}
              </span>
            </div>
            <div class="w-full max-w-sm flex justify-between mb-3">
              <span>
                Taxes *
              </span>
              <span>
                {formatMoney(order.totalTaxV2.amount)}
              </span>
            </div>
            {order.totalRefundedV2.amount !== '0.0' && (
              <div class="w-full max-w-sm flex justify-between mb-3">
                <span>
                  Refunded
                </span>
                <span>
                  - {formatMoney(order.totalRefundedV2.amount)}
                </span>
              </div>
            )}
            <div class="w-full max-w-sm flex justify-between mb-3 border-t pt-3">
              <span className="font-gothamBold">
                Order Total
              </span>
              <span className="font-gothamBold">
                {formatMoney(parseFloat(order.totalPriceV2.amount) - parseFloat(order.totalRefundedV2.amount))}
              </span>
            </div>
          </div>
        </div>
      }
    </div>
  );
}