import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  useCustomerCreateAddress,
  useCustomerUpdateAddress,
} from '@backpackjs/storefront';

import { useCountries } from './useCountries';
import { getOffset } from '@/utils/Helper';

const UK_PROVINCES = [
  'British Forces',
  'England',
  'Northern Ireland',
  'Scotland',
  'Wales',
];

export function AddressForm({ address, closeForm, addressIsDefault }) {
  const { countryNames, countryNamesData } = useCountries({
    firstCountries: [
      'United States',
      'Canada',
      'United Kingdom',
      'United Arab Emirates',
    ],
  });

  const {
    createAddress,
    errors: createErrors,
    setErrors: createSetErrors,
  } = useCustomerCreateAddress();

  const {
    updateAddress,
    errors: updateErrors,
    setErrors: updateSetErrors,
  } = useCustomerUpdateAddress();

  const [mounted, setMounted] = useState(false);
  const [isDefaultAddress, setIsDefaultAddress] = useState(
    addressIsDefault || false
  );
  const [selectedCountryIndex, setSelectedCountryIndex] = useState(null);
  const [provinceNames, setProvinceNames] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState(false);

  const [addressForm, setAddressForm] = useState({
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    city: address?.city || '',
    province: address?.province || '',
    country: address?.country || 'United States',
    zip: address?.zip || '',
    phone: address?.phone || '',
  });

  const clearForm = () => {
    setAddressForm({
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      zip: '',
      phone: '',
    });
    setIsDefaultAddress(false);
  };

  // handles both, createAddress and updateAddress
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!e.target.reportValidity()) {
        const offset = getOffset(e.target.querySelector(':invalid'));
        const header = document.querySelector('.site-header');
        window.scrollTo({
          top: offset.top - header.clientHeight,
          behavior: 'smooth',
        });
        return;
      }
      /* if (!addressForm.country || !addressForm.province) {
        createSetErrors(['Must selected country and province']);
        updateSetErrors(['Must selected country and province']);
        return;
      } */

      if (!address) {
        const newAddress = await createAddress({
          isDefault: isDefaultAddress,
          address: addressForm,
        });

        if (newAddress) {
          clearForm();
          closeForm && closeForm(null);
        }
      } else {
        //! Update address
        const updatedAddress = await updateAddress({
          id: address?.id,
          isDefault: isDefaultAddress,
          address: addressForm,
        });

        if (updatedAddress) {
          clearForm();
          closeForm && closeForm(null);
        }
      }
    },
    [addressForm, isDefaultAddress, address]
  );

  const setCountryIndexOnAddressChange = () => {
    if (!countryNames.length) return;
    let countryIndex = 0;
    countryNames.find((item, index) => {
      if (
        item === address?.country
        // ||
        // (isCreateAddressForm && item === 'United States')
      ) {
        countryIndex = index;
        return true;
      }
      return false;
    });
    setSelectedCountryIndex(countryIndex);
  };

  const setProvincesOnCountryChange = () => {
    if (selectedCountryIndex === null) return;

    const country = countryNamesData[selectedCountryIndex];
    let provincesList = [];

    country.countryName === 'United Kingdom'
      ? (provincesList = UK_PROVINCES)
      : (provincesList = country?.regions?.map((item) => item.name));

    provincesList = ['', ...provincesList];

    if (mounted || !addressForm.province) {
      setAddressForm({
        ...addressForm,
        province:
          !provincesList || provincesList.length < 1 ? null : provincesList[0],
      });
    }

    /* provincesList.find((item, index) => {
      if (item === address?.province) {
        setProvinceSelected(true);
        return true;
      }
      return false;
    }) */
    setProvinceSelected(addressForm.province);

    setProvinceNames(provincesList || []);
    setMounted(true);
  };

  useEffect(() => {
    setCountryIndexOnAddressChange();
  }, [address, countryNames]);
  useEffect(() => {
    setProvincesOnCountryChange();
  }, [selectedCountryIndex]);

  return (
    <form className="-mx-4 flex flex-wrap" onSubmit={handleSubmit} noValidate>
      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="firstName"
            placeholder=" "
            name="firstName"
            type="text"
            value={addressForm.firstName}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                firstName: value,
              })
            }
            required
          />
          <label htmlFor="firstName">First Name</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="lastName"
            placeholder=" "
            name="lastName"
            type="text"
            value={addressForm.lastName}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                lastName: value,
              })
            }
            required
          />
          <label htmlFor="lastName">Last Name</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="address1"
            placeholder=" "
            name="address1"
            type="text"
            value={addressForm.address1}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                address1: value,
              })
            }
            required
          />
          <label htmlFor="address1">Address 1</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="address2"
            placeholder=" "
            name="address2"
            type="text"
            value={addressForm.address2}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                address2: value,
              })
            }
          />
          <label htmlFor="address2">Address 2</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="city"
            placeholder=" "
            name="city"
            type="text"
            value={addressForm.city}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                city: value,
              })
            }
            required
          />
          <label htmlFor="city">City</label>
        </div>
      </div>

      <div className="mb-7 hidden w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <select
            className="selected"
            name="country"
            id="country"
            placeholder=" "
            defaultValue={addressForm.country}
            onChange={({ target: { value: index } }) => {
              if (index === selectedCountryIndex) return;
              setSelectedCountryIndex(index);
              setAddressForm({
                ...addressForm,
                country: countryNames[index],
              });
            }}
          >
            {countryNames.map((country, index) => {
              return (
                <option key={country + index} value={index}>
                  {country}
                </option>
              );
            })}
          </select>
          <label htmlFor="country">Country</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field select-wrap">
          <select
            className={`${provinceSelected ? 'selected' : ''}`}
            name="province"
            id="province"
            placeholder=" "
            defaultValue={addressForm.province}
            onChange={({ target: { value } }) => {
              setProvinceSelected(value);
              setAddressForm({
                ...addressForm,
                province: value,
              });
            }}
            required
          >
            {provinceNames.map((province, index) => {
              return (
                <option
                  key={province + index}
                  value={province}
                  selected={province === addressForm.province}
                  disabled={!province}
                >
                  {province}
                </option>
              );
            })}
          </select>
          <label htmlFor="province">State</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="zip"
            placeholder=" "
            name="zip"
            type="text"
            value={addressForm.zip}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                zip: value,
              })
            }
            required
          />
          <label htmlFor="zip">Zip Code</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 md-:w-1/2 lg:max-w-[358px]">
        <div className="form-field">
          <input
            id="phone"
            placeholder=" "
            name="phone"
            type="text"
            value={addressForm.phone}
            onChange={({ target: { value } }) =>
              setAddressForm({
                ...addressForm,
                phone: value,
              })
            }
          />
          <label htmlFor="phone">Phone Number</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4">
        <div className="flex items-center">
          <input
            className="mr-3"
            id="isDefaultAddress"
            name="isDefaultAddress"
            type="checkbox"
            checked={isDefaultAddress}
            onChange={({ target: { checked } }) => setIsDefaultAddress(checked)}
          />
          <label htmlFor="isDefaultAddress">Set as default</label>
        </div>
      </div>

      <div className="mb-7 w-full px-4 text-center md-:w-1/2 md-:text-left lg:max-w-[358px]">
        <div className="form-field">
          <button
            type="submit"
            className="button button--secondary mb-6 w-full md-:mb-[22px]"
          >
            <span className="text-base">Save</span>
          </button>
          <button type="button" className="focus-link" onClick={closeForm}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}
