import { useCustomerOrders, useCustomerAuth } from '@backpackjs/storefront';
import Subscriptions from '@/snippets/account/AccountNav/YotpoSubscriptions';

import AccountLayout from '../../layouts/AccountLayout';
import { AccountNav } from '@/snippets/account/AccountNav';
import { ArrowLeft } from '@/snippets/icons/ArrowLeft';
import { ArrowRight } from '@/snippets/icons/ArrowRight';
import OrderItem from './OrderItem';

export function Orders() {
  const { customerInit, customer } = useCustomerAuth();

  const {
    orders,
    page,
    hasNext,
    hasPrevious,
    getNext,
    getPrevious,
    ...status
  } = useCustomerOrders({ perPage: 5 });

  if (status.started) {
    return (
      <AccountLayout>
        <div className="order-history max-w-[890px] px-4 pt-2 pb-6 lg:p-0">
          <p>Loading Orders ...</p>
        </div>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout>
      <div className="order-history max-w-[1000px] px-4 pt-4 pb-6 lg:p-0">
        <div className="mx-auto mb-8 lg:hidden">
          <AccountNav />
        </div>
        <div className="subscription-portal-container relative">
          <Subscriptions customer={customer} />
          <div className="bottom-account-gradient"></div>
        </div>
        <div className="yotpo-widget-instance" data-yotpo-instance-id="892619" />
        <div class="order-history-container relative">
          <h3 className="mt-2 mb-2 lg:mb-8">Order History</h3>

          {orders?.length > 0 && (
            <div>
              <div className="order-list-header">
                <div className="md-:w-[18%]">Date</div>
                <div className="md-:w-1/5">Order</div>
                <div className="md-:w-1/5">Status</div>
                <div className="md-:w-1/5">Total</div>
              </div>
              <div className="order-list">
                {orders?.map((order) => {
                  return (
                    <OrderItem key={order.id} orderData={order} />
                  );
                })}
              </div>

              <div className="pagination">
                <button
                  className="mr-5"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    getPrevious();
                  }}
                  disabled={!hasPrevious}
                >
                  <ArrowLeft />
                </button>

                <button
                  className=""
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    getNext();
                  }}
                  disabled={!hasNext}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
          )}
          {orders?.length === 0 && <p className="text-base">There's no order.</p>}
        </div>
      </div>
    </AccountLayout>
  );
}
