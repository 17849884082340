import { useCustomer, useCustomerLogout } from '@backpackjs/storefront';
import { AccountNav } from '@/snippets/account/AccountNav';

function AccountLayout({ children }) {
  const customer = useCustomer();
  const { customerLogout } = useCustomerLogout();

  return (
    <div className="mx-auto flex max-w-[1440px] flex-wrap lg:px-12 lg:py-[60px]">
      <div className="w-full lg:w-1/4 lg:pr-12">
        <div className="flex items-center justify-between bg-grey-9 px-4 py-1 lg:mb-10 lg:flex-col lg:items-start lg:bg-transparent lg:p-0">
          <h6 className="m-0 text-h6-m lg:text-h5">
            Hello {customer.firstName}!
          </h6>
          <button
            type="button"
            aria-label="Sign out"
            className="text-small font-light underline hover:no-underline"
            onClick={async () => {
              await customerLogout(null, () => {
                console.log('Signed Out');
              });
            }}
          >
            Sign Out
          </button>
        </div>
        <div className="hidden lg:block">
          <AccountNav />
        </div>
      </div>

      <div className="w-full lg:w-3/4">{children}</div>
    </div>
  );
}

export default AccountLayout;
