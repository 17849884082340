import Link from 'next/link';

import AccountLayout from '../../layouts/AccountLayout';

export function EditProfile({ customer }) {
  return (
    <AccountLayout>
      <div>
        <h3> Edit Profile </h3>
      </div>
    </AccountLayout>
  );
}
