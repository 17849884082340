import { useState, useMemo } from 'react';
import { useCustomerResetPassword, useRouter } from '@backpackjs/storefront';

export function ResetPassword({ params }) {
  const router = useRouter();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { customerId, resetToken } = params;
  const resetUrl = `https://${process.env.SHOPIFY_DOMAIN}/account/reset/${customerId}/${resetToken}`;

  const {
    resetPassword,
    started,
    finished,
    errors,
    setErrors,
    success,
    reset,
    resetTimer,
  } = useCustomerResetPassword();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 2000);
    }

    return finished
      ? success
        ? 'Password Updated'
        : 'Failed'
      : // not finished yet
      started
      ? 'Updating..'
      : 'Update Password';
  }, [started, success, finished]);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    if (passwordConfirm !== password) {
      setErrors(['Passwords do not match. Please try again.']);

      const resetTimer = setTimeout(() => {
        reset();
        clearTimeout(resetTimer);
      }, 2500);

      return;
    }

    await resetPassword({
      password,
      url: resetUrl,
    });
  };

  return (
    <div className="px-4 py-[50px] lg:py-20">
      <form
        className="mx-auto w-full max-w-[432px] text-center"
        onSubmit={resetPasswordHandler}
      >
        <h3 className="mb-3 lg:mb-4"> Reset Password </h3>
        <p className="text-small mb-7 font-light lg:text-base">
          Enter a new password
        </p>
        <div className="form-field mb-[22px]">
          <input
            id="password"
            name="password"
            type="password"
            placeholder=" "
            minLength="5"
            required
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
          <label htmlFor="password"> Password </label>
        </div>

        <div className="form-field mb-[22px]">
          <input
            className={`${
              errors?.indexOf('Passwords do not match') > -1 ? 'has-error' : ''
            }`}
            name="passwordConfirm"
            placeholder=" "
            type="password"
            minLength="5"
            required
            value={passwordConfirm}
            onChange={({ target: { value } }) => setPasswordConfirm(value)}
          />
          <label htmlFor="passwordConfirm"> Confirm Password </label>
        </div>

        {errors?.length
          ? errors.map((err) => (
              <p key={err} className={styles.formError}>
                {err === 'Unidentified customer'
                  ? 'Incorrect email / password'
                  : 'Something went wrong. Please try again.'}
              </p>
            ))
          : null}
        <button
          className="button button--primary mb-[22px] w-full cursor-pointer"
          disabled={started || (finished && success)}
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
}
