import { useRouter, useCustomerOrder } from '@backpackjs/storefront';

import AccountLayout from '../../layouts/AccountLayout';

export function Order() {
  const router = useRouter();
  // fetch the full order including line-items
  const { order, ...status } = useCustomerOrder({ id: router.query.id });

  if (status.started) {
    return (
      <AccountLayout>
        <p> Loading Order ... </p>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout>
      <div>
        <h3> Order #{order?.orderNumber} </h3>
        <div>
          <h6>Order Status</h6>
          <p>{order?.fulfillmentStatus}</p>
        </div>

        <div>
          <h6>Order Status</h6>
          <p>{order?.processedAt}</p>
        </div>

        <div>
          <h6>Payment Status</h6>
          <p>{order?.financialStatus}</p>
        </div>

        <div>
          <h6>Shipping Address</h6>
          <p>
            {order?.shippingAddress?.firstName}{' '}
            {order?.shippingAddress?.lastName}
          </p>
          <p>{order?.shippingAddress?.address1}</p>
          <p>{order?.shippingAddress?.address2}</p>
          <p>{order?.shippingAddress?.country}</p>
          <p>{order?.shippingAddress?.phone}</p>
        </div>

        <div>
          <h6>Order Items</h6>
          <ul>
            {order?.lineItems?.map((lineItem, index) => {
              return (
                <li key={index}>
                  <p>{lineItem?.variant?.product?.title}</p>
                  <p>{lineItem?.variant?.title}</p>
                  <p>Qty: {lineItem?.quantity}</p>
                </li>
              );
            })}
          </ul>
        </div>

        <div>
          <h6>Order Total</h6>
          <p>
            Subtotal: $
            {Number.parseFloat(order?.subtotalPriceV2?.amount || 0, 2)}
          </p>
          <p>
            Refunded: $
            {Number.parseFloat(order?.totalRefundedV2?.amount || 0, 2)}
          </p>
          <p>
            Shipping: $
            {Number.parseFloat(order?.totalShippingPriceV2?.amount || 0, 2)}
          </p>
          <p>Tax: ${Number.parseFloat(order?.totalTaxV2?.amount || 0, 2)}</p>
          <p>
            Total: ${Number.parseFloat(order?.totalPriceV2?.amount || 0, 2)}
          </p>
        </div>
      </div>
    </AccountLayout>
  );
}

Order.displayName = 'OrderDetails';
