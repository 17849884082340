import { LoginForm } from '@/snippets/account/LoginForm';
import { ForgotPasswordForm } from '@/snippets/account/ForgotPasswordForm';

export function Login({ action, setAction }) {
  return (
    <div>
      {action === 'forgot' ? (
        <ForgotPasswordForm setAction={setAction} />
      ) : (
        <LoginForm setAction={setAction} />
      )}
    </div>
  );
}
