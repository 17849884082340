import { useMemo } from 'react';
import { useCustomerRecoverPassword } from '@backpackjs/storefront';

export function ForgotPasswordForm({ setAction }) {
  const {
    recoverPassword,
    started,
    finished,
    errors,
    success,
    reset,
    resetTimer,
  } = useCustomerRecoverPassword();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 2500);
    }

    return finished
      ? success
        ? 'Email sent'
        : 'Failed'
      : // not finished yet
      started
      ? 'Sending email...'
      : 'Reset Password';
  }, [started, success, finished]);

  const recoverPasswordHandler = async (event) => {
    event.preventDefault();
    const { email } = event.target;
    await recoverPassword({ email: email.value });
  };

  return (
    <div className="px-4 py-[50px] lg:py-20">
      <form
        className="mx-auto w-full max-w-[432px] text-center"
        onSubmit={recoverPasswordHandler}
      >
        <h3 className="mb-3 lg:mb-4"> Forgot Password </h3>
        <p className="text-small mb-7 font-light lg:text-base">
          We will send you an email to reset your password.
        </p>

        {errors?.length
          ? errors.map((err) => (
              <p key={err} className="mb-0 text-[14px] leading-5 text-error">
                {err === 'Unidentified customer'
                  ? 'Incorrect email / password'
                  : 'Something went wrong. Please try again.'}
              </p>
            ))
          : null}

        <div className="form-field mb-[22px]">
          <input
            id="resetEmail"
            placeholder=" "
            name="email"
            type="email"
            required
          />
          <label htmlFor="resetEmail"> Email </label>
        </div>

        <button
          type="submit"
          className="button button--primary mb-[22px] w-full"
          disabled={started || (finished && success)}
        >
          {buttonText}
        </button>

        <button
          type="button"
          className="focus-link cursor-pointer"
          onClick={() => setAction('login')}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}
