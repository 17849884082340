import { Login } from './login';
import { Register } from './register';
import { ResetPassword } from './reset-password';
import { AddressBook } from './address-book';
import { EditProfile } from './edit-profile';
import { Orders } from './orders';
import { Order } from './order';
import { Rewards } from './rewards';
import { Referral } from './referral';

export default {
  activate: null,
  // logged out
  login: Login,
  register: Register,
  'reset-password': ResetPassword,
  // logged in
  'address-book': AddressBook,
  'edit-profile': EditProfile,
  orders: Orders,
  order: Order,
  rewards: Rewards,
  referral: Referral,
};
