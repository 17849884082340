import { useEffect } from 'react'; 
import { useCustomer } from '@backpackjs/storefront';
import Script from 'next/script'; 

import sha256 from 'crypto-js/sha256';

export default function Subscriptions() { 
  const customer = useCustomer(); 

  const injectCustomerPortal = async () => { 
    const fetchToken = (sentAt) => { 
      const customerId = customer?.id?.replace('gid://shopify/Customer/', '');
      const storeId = 'lQ4CeYzdw3OtWNNjZlNguLjoyPQUjQ9VCuuFdAnJ'
      const tokenSecret = 'f3QjFc8tHXGSkTcf1TfV7YYr4YGAOhq1'
      const toSign = `${storeId}_${customerId}_${tokenSecret}_${sentAt}`
      const token = sha256(toSign)
      return token;
    };

    if (customer && customer.id && customer.email) { 
      const sentAt = new Date().toISOString().replace("T", " ").replace("Z", "").replaceAll("-", "/").split('.')[0]; 
      const token = fetchToken(sentAt);

      console.log(token);
      
      const placeholderParent = document.querySelector('.yotpo-subscription-portal')
      const yotpoInstance = placeholderParent.querySelector('.yotpo-widget-instance');
      if (yotpoInstance == null) {
        const placeholder = document.createElement('div')
        placeholder.setAttribute('class', 'yotpo-widget-instance')
        placeholder.setAttribute('data-yotpo-instance-id', '666409')
        placeholder.setAttribute('widget-type', 'SubscriptionsCustomerPortal')
        placeholder.setAttribute('anonymous', 'true')
        placeholder.setAttribute('sent_at', encodeURIComponent(sentAt))
        placeholder.setAttribute('customer_id', customer?.id?.replace('gid://shopify/Customer/', ''))
        placeholder.setAttribute('email', customer.email)
        placeholder.setAttribute('data-email', customer.email)
        placeholder.setAttribute('mode-headless', 'true')
        placeholder.setAttribute('token', token)

        placeholderParent.appendChild(placeholder)

        if (window?.yotpoWidgetsContainer) { 
          window.yotpoWidgetsContainer.initWidgets(); // Initialize the Yotpo widget 
        }
      }
    } 
  }; 

  useEffect(() => { 
    if (customer && customer.id && customer.email) { 
      injectCustomerPortal(); // Call the function to inject the Yotpo portal when the component mounts 
    } 
  }, [customer]); 

  return ( 
    <> 
      <Script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/lQ4CeYzdw3OtWNNjZlNguLjoyPQUjQ9VCuuFdAnJ" async strategy="afterInteractive" /> 
      <div className="yotpo-subscription-portal"></div>
      {!customer && ( 
        <div> 
          <p>Please log in to view your subscriptions.</p> 
        </div> 
      )} 
    </> 
  ); 
} 